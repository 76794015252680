// Free Icons | Font Awesome
// https://fontawesome.com/search?o=r&m=free

export * as arrow_left from './arrow-left-solid.svg'
export * as arrowLeft from './arrow-left.svg'
export * as arrow_right from './arrow-right-solid.svg'
export * as arrowRight from './arrow-right.svg'
export * as chevron_down from './chevron-down-solid.svg'
export * as close from './close.svg'
export * as facebook from './facebook.svg'
export * as instagram from './instagram.svg'
export * as linkedin from './linkedin.svg'
export * as search from './magnifying-glass-solid.svg'
export * as twitter from './twitter.svg'
export * as youtube from './youtube.svg'
