import * as icons from './icons'

import React, {HTMLProps} from 'react'

import css from './Icon.module.scss'
import {fromModule} from 'util/styler/Styler'

export type IconKey = keyof typeof icons

const styles = fromModule(css)

export const Icon: React.FC<
	HTMLProps<HTMLDivElement> & {
		icon: IconKey
	}
> = ({icon, ...rest}) => {
	if (!icons[icon]) {
		console.warn(`Undefined icon key: ${icon}`)
		return null
	}

	const Component = icons[icon].default as any
	return (
		<span {...rest} className={styles.icon.mergeProps(rest).mod(icons[icon])()}>
			<Component />
		</span>
	)
}
