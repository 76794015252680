import {Variant} from 'util/styler'
import {WysiwygClientWrapper} from './WysiwygClientWrapper'

export const Wysiwyg: React.FC<{
	html: string
	className?: string
	mod?: Variant<'inherit'>
}> = ({html, className, mod}) => {
	if (!html) return null
	return (
		<WysiwygClientWrapper className={className} mod={mod}>
			<div dangerouslySetInnerHTML={{__html: html}} />
		</WysiwygClientWrapper>
	)
}
