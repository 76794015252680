import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["Copyright"] */ "/home/runner/work/atletiek.be/atletiek.be/src/components/copyright/Copyright.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/home/runner/work/atletiek.be/atletiek.be/src/components/footer/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/analytics/AnalyticsPageView.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/styles/blocks.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/styles/globals.scss");
