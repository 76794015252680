'use client'

import {CMSMainHomepageChannel} from 'cms-types'
import {Container} from 'layout/Container'
import {FooterCategoriesType} from 'types'
import {Icon} from 'layout/Icon'
import {Link} from 'layout/Link'
import React from 'react'
import {Wysiwyg} from 'layout/wysiwyg/Wysiwyg'
import css from './Footer.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Footer: React.FC<{
	categories: FooterCategoriesType
	social: CMSMainHomepageChannel['social']
	footer_about: CMSMainHomepageChannel['footer_about']
}> = ({categories, social, footer_about}) => {
	return (
		<footer className={styles.footer()}>
			<Container style={{position: 'relative'}}>
				<span className={styles.footer.stamp()} />
				<div className={styles.footer.row()}>
					<FooterCategories categories={categories} />
					<FooterAbout
						className={styles.footer.row.col()}
						social={social}
						footer_about={footer_about}
					/>
				</div>
			</Container>
		</footer>
	)
}

const FooterAbout: React.FC<{
	className?: string
	social: CMSMainHomepageChannel['social']
	footer_about: CMSMainHomepageChannel['footer_about']
}> = ({className, social, footer_about}) => {
	if (!social?.length && !footer_about) return null

	return (
		<div className={styles.about.with(className)()}>
			<FooterSocial className={styles.about.item()} social={social} />
			{footer_about && (
				<div className={styles.about.item()}>
					<Wysiwyg html={footer_about} />
				</div>
			)}
		</div>
	)
}

const FooterCategories: React.FC<{categories: FooterCategoriesType}> = ({
	categories
}) => {
	if (!categories || categories.length === 0) return null

	return (
		<>
			{categories.map(
				(category) =>
					(category?.title || category?.url) && (
						<div className={styles.footer.row.col()} key={category.id}>
							<Link href={category.url} className={styles.category()}>
								{category.title}
							</Link>
							<FooterSubcategories subcategories={category.children} />
						</div>
					)
			)}
		</>
	)
}

const FooterSubcategories: React.FC<{
	subcategories: {id: number; url: string; title: string}[]
}> = ({subcategories}) => {
	if (!subcategories || subcategories.length === 0) return null

	return (
		<div className={styles.subcategories()}>
			{subcategories.map((subcategory) => (
				<div className={styles.subcategories.item()} key={subcategory.id}>
					<Link
						href={subcategory.url}
						className={styles.subcategories.item.link()}
					>
						{subcategory.title}
					</Link>
				</div>
			))}
		</div>
	)
}

const FooterSocial: React.FC<{
	className?: string
	social: CMSMainHomepageChannel['social']
}> = ({className, social}) => {
	if (!social || social.length === 0) return null

	return (
		<div className={styles.social.with(className)()}>
			<div className={styles.social.items()}>
				{social.map(
					(item, i) =>
						item?.type &&
						item?.link?.url && (
							<div className={styles.social.items.item()} key={i}>
								<Link
									href={item.link.url}
									title={item.link.description || item.link.title}
									className={styles.social.items.item.link()}
								>
									<Icon icon={item.type} />
								</Link>
							</div>
						)
				)}
			</div>
		</div>
	)
}
