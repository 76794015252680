import {HTMLAttributes, PropsWithChildren} from 'react'

import css from './Container.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

type ContainerProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>

export const Container: React.FC<ContainerProps> = (props) => (
	<div {...props} className={styles.container.mergeProps(props)()} />
)
