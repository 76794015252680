'use client'

import {usePathname, useSearchParams} from 'next/navigation'

import {ltrim} from 'util/ltrim'
import {useEffect} from 'react'

declare const window: Window & {dataLayer: Record<string, any>[]}
export const pageview = (url: string, title?: string) => {
	const trimmedUrlParts = ltrim(url, '/').split('/')

	// do not track the preview pages
	if (trimmedUrlParts.length > 0 && trimmedUrlParts[0] === 'preview')
		return null

	window.dataLayer?.push({
		pagePath: url,
		pageTitle: title
	})
}

const AnalyticsPageView = ({title}: {title?: string}) => {
	const pathname = usePathname()
	const searchParams = useSearchParams()

	useEffect(() => {
		if (pathname) pageview(pathname, title)
	}, [pathname, searchParams, title])

	return null
}

export default AnalyticsPageView
