'use client'

import React, {Fragment} from 'react'

import {CMSLink} from 'cms-types'
import {Container} from 'layout/Container'
import {Link} from 'layout/Link'
import {fromModule} from 'util/styler/Styler'
import css from './Copyright.module.scss'

const styles = fromModule(css)

export const Copyright: React.FC<{
	links: CMSLink[]
}> = ({links}) => {
	return (
		<section className={styles.copyright()}>
			<Container>
				<div className={styles.copyright.row()}>
					<div className={styles.copyright.row.col()}>
						{links?.length > 0 &&
							links.map(
								(link, i) =>
									link?.url && (
										<Fragment key={i}>
											{i > 0 && (
												<span className={styles.copyright.divider()}>|</span>
											)}
											<Link href={link.url} className={styles.copyright.link()}>
												{link.description || link.title}
											</Link>
										</Fragment>
									)
							)}
					</div>
					<div className={styles.copyright.row.col()}>
						<p>
							Website door{' '}
							<Link
								href="https://codeurs.be"
								className={styles.copyright.link.mod('underline')()}
								rel="external"
							>
								Codeurs
							</Link>
						</p>
					</div>
				</div>
			</Container>
		</section>
	)
}
